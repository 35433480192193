
.coupons-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    align-self: center;
    width: calc(65vw + 55px);
    height: auto;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: flex-start;
}

.small-title{
    width: inherit;
    color: rgba(0,0,0,.3);
    font-size: 12px;
    font-weight: 100;
    line-height: 11px;
    text-align: right;
    float: right;
    padding: 5px 0 5px 6px;
}

.bigdeals-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: inherit;
    border: 1px solid #ffdfc8;
    background: #fffaec;
    padding: 16px;
    margin-bottom: 1%;
}

.bigdeals-container:hover{
    background: #fceac2;
}

.deals-image{
    width: 76px;
    padding-right: 16px;
}

.bigdeals-image-container{
    display: flex;
    align-items: center;
}

.smalldeals-image-container{
    display: flex;
    align-items: center;
}

.bigdeals-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;

}

.bigdeals-content-bottom{
    width: 75%;
}

.bigdeals-content > h2 :hover{
    text-decoration: underline;
    color: rgb(255, 110, 64);;
}

.bigdeals-links{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #4736c0;
    width: 20%;
    margin: 1vh;
    font-weight: 600;
}

.bigdeals-links-bottom{
    flex-direction: row;
    width: 70%;
}

.bigdeals-link::before {
    content: "▶︎";
    font-size: 0.85em;
    padding-right: 8px;
    color: rgb(255, 169, 67);
}

.bigdeals-link:hover {
    text-decoration: underline;
    color: rgb(255, 110, 64);
}

.bigdeals-button{
    background: rgb(255, 110, 64);
    color: white;
    border: none;
    font-size: 19px;
    font-weight: 700;
    border-radius: 5px;
    height: 50px;
    width: 160px;
    cursor: pointer;
}

.bigdeals-button-container{
    display: flex;
    align-items: center;
    width: 14%;
}

.bigdeals-button:hover{
    background: rgb(250, 130, 96);
}

.bigdeals-button::after{
    content: "ᐳ";
    font-size: 1em;
    float: right;
    display: block;
    font-weight: normal;
    padding: 0px 0.65em 0px 0.2em;
}

a {
    text-decoration: none;
}

.smalldeals-header{
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.smalldeals-container{
    width: inherit;
    font-size: 14px;
    line-height: 1.25em;
    background: rgba(255,255,255,.15);
    position: relative;
    display: flex;
    align-items: stretch;
    float: none;
    margin-bottom: 0.25em;
    box-shadow: 0px -1px 0px rgba(0,0,0,.1);
}

.smalldeals-content{
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.smalldeals-content-title{
    font-size: 16px;
    line-height: 1.33;
    color: #000;
    padding: 0;
    margin: 0 0 0.25em 0;
    text-decoration: none;
    cursor: pointer;
}

.smalldeals-content-title:hover{
    text-decoration: underline;
    color: rgb(255, 110, 64);
}

.smalldeals-content-desc{
    color: rgba(0,0,0,.55);
}

@media screen and (max-width: 1023px) {
    .coupons-container{
        width: 73vw;
        height: auto;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        justify-content: flex-start;
    }

    .bigdeals-content > h2 {
        font-size: medium;
        font-weight: 700;
        line-height: 1.15;
        margin: 0;
    }

    .bigdeals-content > div > span{
        font-size: small;
    }

    .bigdeals-links {
        font-size: small;
        width: 34%;
    }
}

@media screen and (max-device-width: 600px) {
    .coupons-container{
        width: 95vw;
        height: auto;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        justify-content: center;
    }

    .bigdeals-container{
        width: 80vw;
        flex-direction: column;
        margin-bottom: 4%;
        padding: 10px 16px 0 16px;
        flex-wrap: wrap;
    }

    .bigdeals-button::after{
        display: none;
    }

    .bigdeals-button::before{
        content: "ᐳ";
        font-size: 1.5em;
        float: right;
        display: block;
        font-weight: bolder;
        padding: 0px 0.65em 0px 0.2em;
    }

    .bigdeals-image-container{
        display: none;
    }

    smalldeals-image-container{
        justify-content: center;
    }

    .bigdeals-content{
        width: 80%;
    }

    .bigdeals-links {
        flex-wrap: wrap;
        width: 83%;
        height: 21%;
        flex-direction: row;
    }

    .bigdeals-button-container{
        justify-content: center;
        width: 17%;
        height: 58px;
        position: absolute;
        right: 23px;
    }

    .smalldeals-content-title, .smalldeals-content-desc {
        font-size: 13px;
    }

    .bigdeals-button{
        background: rgb(255, 110, 64);
        color: white;
        border: none;
        font-size: 13px;
        font-weight: 900;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 10px;
    }
}