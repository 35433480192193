.content-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    align-self: center;
    width: calc(65vw + 55px);
    height: auto;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: flex-start;
}

.title {
    color: #000099;
    font-size: 26pt;
    font-weight: bold;
}

@media screen and (max-width: 1023px) {
    .content-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        align-self: center;
        width: 73vw;
        height: auto;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        justify-content: flex-start;
    }
}

@media screen and (max-device-width: 600px) {
    .content-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        align-self: center;
        width: 95vw;
        height: auto;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        justify-content: center;
    }
}