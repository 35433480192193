.search-banner-container {
    display: flex;
    justify-content: center;
}

.search-banner {
    -webkit-padding-start: 0;
    box-sizing: border-box;
    font-family: Roboto,sans-serif;
    font-size: .9rem;
    font-weight: 400;
    padding-inline-start: 0;
    width: calc(65vw + 55px);
}

.search-banner-inner, .search-banner>header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

.search-banner>header {
    color: #bdbdbd;
    font-size: .7rem;
}

.search-banner li {
    list-style-type: none;
}

.search-banner-inner {
    align-items: center;
    background: #fff;
    border: 0.1rem solid #e0e0e0;
    box-shadow: 0.25rem 0.25rem 1rem -0.5rem rgba(0,0,0,.2);
    box-sizing: border-box;
    color: inherit;
    font-family: sans-serif;
    font-size: .8rem;
    font-weight: 400;
    list-style: none;
    margin: 0;
    text-decoration: none;
}

.search-banner-inner:after {
    background: #0f4d92;
    border-radius: 0.2rem;
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='1rem' height='1rem' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.952 13.776h-.963l-.341-.329a7.885 7.885 0 0 0 1.913-5.154 7.92 7.92 0 1 0-7.92 7.92 7.884 7.884 0 0 0 5.154-1.913l.329.341v.963l6.092 6.08 1.815-1.816-6.08-6.092zm-7.31 0a5.475 5.475 0 0 1-5.484-5.483A5.475 5.475 0 0 1 8.641 2.81a5.476 5.476 0 0 1 5.483 5.483 5.476 5.476 0 0 1-5.483 5.483z' fill='%23fff'/%3E%3C/svg%3E");
    height: 1rem;
    padding: 0.4rem;
    width: 1rem;
}