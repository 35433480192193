.full-article-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin: auto;
    width: 50vw;
    flex-direction: column;
    font-size: larger;
}

.image{
    width:50%;
}

.container{
    width: inherit;
    font-size: 11pt;
}

.container img{
    width: 50%;
}

.topics-container{
    width: 100%;
    margin-bottom: 7vh;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
}
.topics-grid{
    width: 100%;
    display: flex;
    gap: 4px;
    max-height: 250px;
    max-width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.related-item {
    border: 1px solid darkgrey;
    border-left: 6px solid #D8D7F7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 5vh;
    width: 33%;
    color: #555555;
    padding: 2px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to left, transparent 50%, rgba(216, 215, 254, 0.3) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    border-radius: 1px;
    transition: all .2s ease;
}
.related-item p {
    margin-left: 1rem;
    margin-right: 7px;
}
.related-item span {
    background-image: url(https://se-p-static-content.seccint.com/contextual-demo/purple_arrow.svg);
    font-size: 0;
    width: 11px;
    min-width: 10px;
    height: 20px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
}
.related-item:hover {
    background-position: left bottom;
}
.tiny-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.tiny-title p{
    margin-bottom: 3px;
    font-size: 13px;
    color: #4b4c54;
}

.arrow {
    border: solid black;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 9px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

@media screen and (max-device-width: 600px) {
    .image{
        width:90%;
    }

    .full-article-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        width: 90vw;
        flex-direction: column;
    }

    .container img{
        width: 80vw
    }

}
@media screen and (max-width: 1024px) {
    .full-article-container {
        width: 70vw; /* Wider container on smaller screens */
    }
    .image, .container img {
        width: 70%;
    }
    .topics-container {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .topics-grid {
        gap: 3px;
        max-height: 270px;
    }

    .related-item {
        font-size: 12px;
    }
}